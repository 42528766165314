import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SelectOrderTypePage } from './select-order-type.page';

const routes: Routes = [
  {
    path: '',
    component: SelectOrderTypePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SelectOrderTypePageRoutingModule { }
