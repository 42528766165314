import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ToppingsPageRoutingModule } from './toppings-routing.module';

import { ToppingsPage } from './toppings.page';
import { TranslateModule } from '@ngx-translate/core';

import { PipeModule } from '../../../core/pipe.module';
import {ModalModule} from "../../../layout/modal/modal.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ToppingsPageRoutingModule,
        TranslateModule,
        PipeModule,
        ModalModule
    ],
  declarations: [ToppingsPage],
  exports: [ToppingsPage]
})
export class ToppingsPageModule { }
