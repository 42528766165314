import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  constructor() { }
  
  @ContentChild('start') start: TemplateRef<any>;
  @ContentChild('middle') middle: TemplateRef<any>;
  @ContentChild('end') end: TemplateRef<any>;

  ngOnInit() {}

}
