import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { DeliveryMethodSelectComponent } from '../delivery-method-select/delivery-method-select.component';
import { FoodFlowChangeDeliveryComponent } from './food-flow-change-delivery.component';
import { PipeModule } from 'src/app/core/pipe.module';



@NgModule({
  declarations: [FoodFlowChangeDeliveryComponent],
  imports: [ 
    CommonModule,
    TranslateModule,
    IonicModule,
    PipeModule
  ],
  exports : [
    FoodFlowChangeDeliveryComponent
  ]
})
export class FoodFlowChangeDeliveryModule { }
