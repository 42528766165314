import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppServiceService } from 'src/app/api/app-service.service';
import { FoodFlowChangeDeliveryPopupPage } from 'src/app/views/modals/food-flow-change-delivery-popup/food-flow-change-delivery-popup.page';
import { SandwichKingsSelectOrderTypePage } from 'src/app/views/modals/sandwich-kings-select-order-type/sandwich-kings-select-order-type.page';

import { SelectOrderTypePage } from 'src/app/views/modals/select-order-type/select-order-type.page';

@Component({
  selector: 'app-food-flow-change-delivery',
  templateUrl: './food-flow-change-delivery.component.html',
  styleUrls: ['./food-flow-change-delivery.component.scss'],
})
export class FoodFlowChangeDeliveryComponent implements OnInit {

  constructor(public appService: AppServiceService, public modalController: ModalController) { }

  ngOnInit() {}


 async changeDilivaryMethod(){

  

  if (this.appService.appObject.selectedRestaurantDetails && this.appService.appObject.selectedRestaurantDetails.openDetails)
    this.appService.findSelectedRestaurantOrderTypes(this.appService.appObject.selectedRestaurantDetails.openDetails);

  const cartModal = await this.modalController.create({
    component: FoodFlowChangeDeliveryPopupPage,
    cssClass: 'custom-popup',
    componentProps: {
      'just_select_order_type': true,
      'currentUI': 'order-type'
    }
  });

  await cartModal.present();

    
  }

}
